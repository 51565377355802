import * as Actions from '@actions/index';
import {
    ChartColors,
    ColumnTypes,
    CustomerLogo,
    DashletAlarmOverview,
    LineGraphData,
    RealtimeGatewayDocument,
    SeverityRatioBar
} from '@models/index';
import { AppState, selectDataFromCommonEntity, selectEntity, selectEntityTimer } from '@reducers/index';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BarGraphData } from '@models/visualisation/barGraphData';
import { DashletService, TimeService } from '@services/index';
import { Subject, Subscription, catchError, filter, map, takeUntil } from 'rxjs';
import {
    AlarmByEquipment,
    AlarmByName,
    AlarmBySeverity,
    AlarmModel,
    AlarmTimeDocument
} from '@models/dataObjects/interfaces/AlarmOverview.model';
import { environment } from '@environments/environment';

interface AlarmEquipmentDocument extends RealtimeGatewayDocument {
    data: AlarmEquipmentData[];
}

interface AlarmEquipmentData {
    AlarmDescription: string;
    AlarmIds: string;
    AlarmName: string;
    AlarmSeverity: string;
    EquipmentId: string;
    EquipmentName: string;
    TotalAlarms: string;
}

interface AlarmChartData {
    High: number;
    Medium: number;
    Low: number;
    data: BarGraphData;
}
interface TimeChartData {
    High: number;
    Medium: number;
    Low: number;
    data: LineGraphData;
}

interface AlarmCount {
    alarmName: string;
    count: number;
    severity: number;
    resolved: number;
    unresolved: number;
    ids?: string[];
}

interface EquipmentAlarm {
    equipmentId: string;
    equipmentName: string;
    count: number;
    alarms: AlarmCount[];
}

interface Alarm {
    alarmName: string;
    count: number;
    severity: number;
    resolvedAlarms: number;
    unresolvedAlarms: number;
    equipments: string[];
    expansion: AlarmNameRowExpansion[];
}

interface AlarmNameRowExpansion {
    equipmentName: string;
    count: number;
}

@Component({
    selector: 'dashlet-alarm-overview',
    templateUrl: 'dashlet-alarm-overview.component.html',
    styleUrls: ['dashlet-alarm-overview.component.scss']
})
export class DashletAlarmOverviewComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletAlarmOverview;

    private subscription: Subscription = new Subscription();

    public chartColors: ChartColors = this.dashletService.getChartColors();

    public alarmTableLoading = true;

    public tableButtons = [
        { label: 'Alarm', selected: true },
        { label: 'Equipment', selected: false }
    ];

    public chartButtons = [
        { label: 'Alarm', selected: true },
        { label: 'Equipment', selected: false },
        { label: 'Created', selected: false }
    ];

    public equipmentLegend = [
        //active/unresolved colours
        {
            label: 'Low',
            colour: this.chartColors.blue,
            width: '30%',
            title: 'Top 15 alarms with severity > 4'
        },
        {
            label: 'Medium',
            colour: this.chartColors.amber,
            width: '30%',
            title: 'Top 15 alarms with severity of 3 or 4'
        },
        {
            label: 'High',
            colour: this.chartColors.red,
            width: '30%',
            title: 'Top 15 alarms with severity of 1 or 2'
        }
    ];

    public alarmLegend = [
        //resolved colours
        {
            label: 'Low',
            colour: this.chartColors.blueDark,
            width: '30%',
            title: 'Top 15 alarms with severity > 4'
        },
        {
            label: 'Medium',
            colour: this.chartColors.amberDark,
            width: '30%',
            title: 'Top 15 alarms with severity of 3 or 4'
        },
        {
            label: 'High',
            colour: this.chartColors.redDark,
            width: '30%',
            title: 'Top 15 alarms with severity of 1 or 2'
        }
    ];

    public showEquipmentTable = false;
    public showChart: number = 0;
    public alarmChartOptions: any = {
        title: '',
        scales: {
            x: {
                grid: {
                    display: false
                },
                stacked: true,
                ticks: {
                    fontSize: 10.2,
                    autoSkip: false,
                    maxRotation: 60,
                    minRotation: 60
                }
            },
            y: {
                stacked: true,
                grid: {
                    display: false
                },
                ticks: {
                    fontSize: 10.2,
                    beginAtZero: true
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
                reverse: false
            },
            tooltip: {
                callback: {
                    title: tipItem => {
                        return null;
                    }
                }
            }
        },

        animation: {
            duration: 0
        }
    };

    public timeChartOptions: any = {
        title: '',
        scales: {
            x: {
                type: 'time',
                grid: {
                    display: false
                },
                time: {
                    unit: 'hour',
                    displayFormats: {
                        day: 'HH'
                    },
                    stepSize: 2,
                    max: null,
                    min: null
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                }
            },
            y: {
                stacked: true,
                grid: {
                    display: false
                },
                ticks: {
                    fontSize: 10.2,
                    beginAtZero: true
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
                reverse: true
            },
            tooltip: {
                callback: {
                    title: tipItem => {
                        return null;
                    }
                }
            }
        },

        animation: {
            duration: 0
        }
    };

    public alarmColumns: ColumnTypes[] = [
        {
            columnDef: 'expand',
            header: ''
        },
        {
            columnDef: 'alarmName',
            header: 'Alarm Name',
            width: '50%',
            isLinkHeader: true,
            linkHeaderToolTip: 'Open Alarms report',
            linkCallbackNavigation: () => {
                window
                    .open(
                        `${environment.reportingUrl}viewer?entityId=${this.dashlet.customer.customerId}&reportId=27a13f36-840d-48c7-b1ff-615a8527e846&reportName=Alarms`,
                        '_blank'
                    )
                    .focus();
            }
        },
        {
            columnDef: 'count',
            header: 'Count',
            type: 'numeric'
        },
        {
            columnDef: 'resolvedAlarms',
            header: 'Status',
            cell: (row: Alarm): SeverityRatioBar[] => {
                return [
                    {
                        value: row.resolvedAlarms,
                        colour: this.getSeverityColour(row.severity, true),
                        tooltip: `${row.resolvedAlarms}`
                    },
                    {
                        value: row.unresolvedAlarms,
                        colour: this.getSeverityColour(row.severity, false),
                        tooltip: `${row.unresolvedAlarms}`
                    }
                ];
            },
            type: 'ratio'
        }
    ];

    public alarmExpansionColumns: ColumnTypes[] = [
        {
            columnDef: 'equipmentName',
            header: 'Equipment Name'
        },
        {
            columnDef: 'count',
            header: 'Count',
            type: 'numeric'
        }
    ];

    public equipmentColumns: ColumnTypes[] = [
        {
            columnDef: 'expand',
            header: ''
        },
        {
            columnDef: 'equipmentName',
            header: 'Equipment Name',
            isLinkHeader: true,
            linkHeaderToolTip: 'Open Alarms report',
            linkCallbackNavigation: () => {
                window
                    .open(
                        `${environment.reportingUrl}viewer?entityId=${this.dashlet.customer.customerId}&reportId=27a13f36-840d-48c7-b1ff-615a8527e846&reportName=Alarms`,
                        '_blank'
                    )
                    .focus();
            }
        },
        {
            columnDef: 'count',
            header: 'Count',
            type: 'numeric'
        },
        {
            columnDef: 'resolvedAlarms',
            header: 'Status',
            cell: (row: EquipmentAlarm): SeverityRatioBar[] => {
                return row.alarms.flatMap(alarm => {
                    return [
                        {
                            value: alarm.resolved,
                            colour: this.getSeverityColour(alarm.severity, true),
                            tooltip: `${alarm.resolved}`
                        },
                        {
                            value: alarm.unresolved,
                            colour: this.getSeverityColour(alarm.severity, false),
                            tooltip: `${alarm.unresolved}`
                        }
                    ];
                });
            },
            type: 'ratio'
        }
    ];

    public equipmentExpansionColumns: ColumnTypes[] = [
        {
            columnDef: 'alarmName',
            header: 'Alarm Name'
        },
        {
            columnDef: 'count',
            header: 'Count',
            type: 'numeric'
        }
    ];

    public equipmentChartData: AlarmChartData;

    public equipmentTableData: EquipmentAlarm[];

    public alarmTableData: Alarm[];

    public alarmChartData: AlarmChartData;

    public timeChartData: TimeChartData;

    public destroy$: Subject<null> = new Subject<null>();

    constructor(
        private store$: Store<AppState>,
        private dashletService: DashletService,
        private timeService: TimeService
    ) {}

    ngOnInit() {
        this.dashlet.settingsChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.clearData();
            this.requestData();
        });
        if (this.dashlet.configured) this.requestData();
    }

    private requestData(): void {
        this.dashlet.lastUpdated = this.timeService.convertToUseTimeZoneForLastUpdated(new Date().toLocaleString());
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );

        const nowMinus24Hours = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
        const date = new Date(
            nowMinus24Hours.getFullYear(),
            nowMinus24Hours.getMonth(),
            nowMinus24Hours.getDate(),
            nowMinus24Hours.getHours(),
            0,
            0
        );

        const id = this.dashlet.customer.customerId;

        if (this.dashlet.customer) {
            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: id,
                    command: this.dashlet.ALARM_SUMMARY_BY_EQUIPMENT_COMMAND_TYPE_ID,
                    idType: 'customerId'
                })
            );
            this.store$.dispatch(
                Actions.GetCommonHistoric({
                    equipmentId: id,
                    commandTypeId: this.dashlet.ALARM_SUMMARY_BY_EQUIPMENT_COMMAND_TYPE_ID,
                    from: date,
                    to: new Date(),
                    max: 24
                })
            );

            this.store$.dispatch(
                Actions.GetEntityTimer({
                    equipmentId: id,
                    commandTypeId: this.dashlet.ALARM_SUMMARY_BY_EQUIPMENT_COMMAND_TYPE_ID,
                    uniqueId: id + this.dashlet.ALARM_SUMMARY_BY_EQUIPMENT_COMMAND_TYPE_ID
                })
            );

            this.subscription.add(
                this.store$
                    .pipe(select(selectEntityTimer(id + this.dashlet.ALARM_SUMMARY_BY_EQUIPMENT_COMMAND_TYPE_ID)))
                    .subscribe(dataTimeoutExpired => {
                        if (dataTimeoutExpired !== undefined) {
                            this.dashlet.alarmDataTimedOut = dataTimeoutExpired;
                        }
                    })
            );

            const alarmSummary$ = this.store$.pipe(
                select(selectDataFromCommonEntity(id + this.dashlet.ALARM_SUMMARY_BY_EQUIPMENT_COMMAND_TYPE_ID)),
                map((alarmByEquipment: AlarmEquipmentDocument[]) => {
                    if (alarmByEquipment) {
                        const time: Date = new Date(
                            this.timeService.convertToUseTimeZoneForLastUpdated(alarmByEquipment[0].timestamp)
                        );
                        if (new Date(this.dashlet.lastUpdated) < time)
                            this.dashlet.lastUpdated = this.timeService.convertToUseTimeZoneForLastUpdated(
                                alarmByEquipment[0].timestamp
                            );
                        this.dashlet.refreshNameTag();

                        const summaryData = this.dashlet.summariseAlarmsData(alarmByEquipment);

                        if (summaryData.length === 0) {
                            this.alarmTableLoading = false;
                            this.alarmChartData = this.processAlarmChartData([]);
                            this.equipmentChartData = this.processEquipmentChartData([]);
                            this.timeChartData = this.processTimeChartData([]);
                            return [];
                        }

                        return summaryData;
                    }
                }),
                filter((summaryData: AlarmModel[]) => {
                    return summaryData && summaryData.length > 0;
                }),
                catchError(err => {
                    this.alarmTableLoading = false;
                    return [];
                })
            );

            this.subscription.add(
                alarmSummary$.subscribe((data: AlarmModel[]) => {
                    if (data) {
                        this.alarmTableLoading = false;
                        this.alarmTableData = this.processAlarmTableData(data);
                        this.equipmentTableData = this.processEquipmentTableData(data);
                    }
                })
            );

            this.subscription.add(
                alarmSummary$
                    .pipe(
                        map((summaryData: AlarmModel[]) => {
                            if (summaryData && summaryData.length > 0) {
                                return this.dashlet.groupSummaryDataByEquipment(summaryData);
                            }
                        })
                    )
                    .subscribe(data => {
                        if (data) {
                            this.equipmentChartData = this.processEquipmentChartData(data);
                        }
                    })
            );

            this.subscription.add(
                alarmSummary$
                    .pipe(
                        map((summaryData: AlarmModel[]) => {
                            if (summaryData && summaryData.length > 0) {
                                return this.dashlet.groupSummaryDataByName(summaryData);
                            }
                        })
                    )
                    .subscribe(data => {
                        if (data) {
                            this.alarmChartData = this.processAlarmChartData(data);
                        }
                    })
            );

            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: id,
                    command: this.dashlet.ALARM_SUMMARY_BY_SEVERITY_COMMAND_TYPE_ID,
                    idType: 'customerId'
                })
            );

            this.store$.dispatch(
                Actions.GetCommonHistoric({
                    equipmentId: id,
                    commandTypeId: this.dashlet.ALARM_SUMMARY_BY_SEVERITY_COMMAND_TYPE_ID,
                    from: date,
                    to: new Date(),
                    max: 24
                })
            );

            this.store$.dispatch(
                Actions.GetEntityTimer({
                    equipmentId: id,
                    commandTypeId: this.dashlet.ALARM_SUMMARY_BY_SEVERITY_COMMAND_TYPE_ID,
                    uniqueId: id + this.dashlet.ALARM_SUMMARY_BY_SEVERITY_COMMAND_TYPE_ID
                })
            );

            this.subscription.add(
                this.store$
                    .pipe(select(selectEntityTimer(id + this.dashlet.ALARM_SUMMARY_BY_SEVERITY_COMMAND_TYPE_ID)))
                    .subscribe(dataTimeoutExpired => {
                        if (dataTimeoutExpired !== undefined) {
                            this.dashlet.alarmSeverityDataTimedOut = dataTimeoutExpired;
                        }
                    })
            );

            const alarmSeverity$ = this.store$.pipe(
                select(selectDataFromCommonEntity(id + this.dashlet.ALARM_SUMMARY_BY_SEVERITY_COMMAND_TYPE_ID)),
                map((alarmBySeverity: AlarmTimeDocument[]) => {
                    if (alarmBySeverity) {
                        return this.dashlet.sumariseSeverityData(alarmBySeverity);
                    }
                }),
                filter((summaryData: AlarmBySeverity[]) => {
                    return summaryData && summaryData.length > 0;
                })
            );

            this.subscription.add(
                alarmSeverity$.subscribe((data: AlarmBySeverity[]) => {
                    if (data) {
                        this.timeChartData = this.processTimeChartData(data);
                    }
                })
            );
        }
    }

    public clearData(): void {
        this.subscription.unsubscribe();
        this.dashlet.resetData();
        this.alarmTableLoading = true;
        this.alarmChartData = null;
        this.equipmentChartData = null;
        this.timeChartData = null;
        this.alarmTableData = [];
        this.equipmentTableData = [];
        this.subscription = new Subscription();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.destroy$.next(null);
    }

    smallLayout() {
        return this.dashlet.getSize().id === 1;
    }

    get largeLayout() {
        return this.dashlet.getSize().id === 0;
    }
    /* ---------- ACCORIDON TOGGLE METHODS ---------- */

    public changeTable() {
        this.tableButtons.map(button => (button.selected = !button.selected));
        this.showEquipmentTable = !this.showEquipmentTable;
    }

    private processAlarmChartData(data: AlarmByName[]): AlarmChartData {
        const returnData: AlarmChartData = {
            High: 0,
            Medium: 0,
            Low: 0,
            data: {
                options: {},
                labels: [],
                datasets: [
                    {
                        label: 'Low resolved',
                        backgroundColor: this.getSeverityColour(5, true),
                        borderColor: this.getSeverityColour(5, true),
                        fill: true,
                        data: [],
                        borderWidth: 1,
                        borderRadius: 2,
                        borderSkipped: true
                    },

                    {
                        label: 'Medium resolved',
                        backgroundColor: this.getSeverityColour(3, true),
                        borderColor: this.getSeverityColour(3, true),
                        fill: true,
                        data: [],
                        borderWidth: 1,
                        borderRadius: 2,
                        borderSkipped: true
                    },

                    {
                        label: 'High resolved',
                        backgroundColor: this.getSeverityColour(1, true),
                        borderColor: this.getSeverityColour(1, true),
                        fill: true,
                        data: [],
                        borderWidth: 1,
                        borderRadius: 2,
                        borderSkipped: true
                    },
                    {
                        label: 'Low active',
                        backgroundColor: this.getSeverityColour(5, false),
                        borderColor: this.getSeverityColour(5, false),
                        fill: true,
                        data: [],
                        borderWidth: 1,
                        borderRadius: 2,
                        borderSkipped: true
                    },
                    {
                        label: 'Medium active',
                        backgroundColor: this.getSeverityColour(3, false),
                        borderColor: this.getSeverityColour(3, false),
                        fill: true,
                        data: [],
                        borderWidth: 1,
                        borderRadius: 2,
                        borderSkipped: true
                    },
                    {
                        label: 'High active',
                        backgroundColor: this.getSeverityColour(1, false),
                        borderColor: this.getSeverityColour(1, false),
                        fill: true,
                        data: [],
                        borderWidth: 1,
                        borderRadius: 2,
                        borderSkipped: true
                    }
                ]
            }
        };

        data.sort((a, b) => b.TotalAlarms - a.TotalAlarms)
            .slice(0, 14)
            .forEach((alarm, index) => {
                if (alarm.Severity === 1 || alarm.Severity === 2) {
                    returnData.High += alarm.TotalAlarms;
                } else if (alarm.Severity === 3 || alarm.Severity === 4) {
                    returnData.Medium += alarm.TotalAlarms;
                } else if (alarm.Severity > 4) {
                    returnData.Low += alarm.TotalAlarms;
                }

                returnData.data.datasets[2].data[index] = 0;
                returnData.data.datasets[5].data[index] = 0;
                returnData.data.datasets[1].data[index] = 0;
                returnData.data.datasets[4].data[index] = 0;
                returnData.data.datasets[0].data[index] = 0;
                returnData.data.datasets[3].data[index] = 0;

                if (alarm.Severity === 1 || alarm.Severity === 2) {
                    returnData.data.datasets[2].data[index] += alarm.Resolved;
                    returnData.data.datasets[5].data[index] += alarm.Unresolved;
                } else if (alarm.Severity === 3 || alarm.Severity === 4) {
                    returnData.data.datasets[1].data[index] += alarm.Resolved;
                    returnData.data.datasets[4].data[index] += alarm.Unresolved;
                } else if (alarm.Severity > 4) {
                    returnData.data.datasets[0].data[index] += alarm.Resolved;
                    returnData.data.datasets[3].data[index] += alarm.Unresolved;
                }
                returnData.data.labels[index] = this.truncateString(alarm.AlarmName, 15);
            });

        return returnData;
    }

    private processAlarmTableData(alarmData: AlarmModel[]): Alarm[] {
        const alarmTableData = alarmData.reduce((accumulator: Alarm[], current: AlarmModel) => {
            const found = accumulator.find(a => a.alarmName === current.Alarm.AlarmName);

            if (!found) {
                accumulator.push({
                    alarmName: current.Alarm.AlarmName,
                    count: current.Alarm.TotalAlarms,
                    severity: current.Alarm.Severity,
                    resolvedAlarms: current.Alarm.ResolvedAlarms.length,
                    unresolvedAlarms: current.Alarm.UnresolvedAlarms.length,
                    equipments: [current.EquipmentId],
                    expansion: [
                        {
                            equipmentName: current.EquipmentName,
                            count: current.Alarm.TotalAlarms
                        }
                    ]
                });

                return accumulator;
            }

            found.count += current.Alarm.TotalAlarms;
            found.resolvedAlarms += current.Alarm.ResolvedAlarms.length;
            found.unresolvedAlarms += current.Alarm.UnresolvedAlarms.length;
            found.equipments.push(current.EquipmentId);
            found.expansion.push({
                equipmentName: current.EquipmentName,
                count: current.Alarm.TotalAlarms
            });

            return accumulator;
        }, []);

        return alarmTableData;
    }

    private processEquipmentChartData(alarmData: AlarmByEquipment[]): AlarmChartData {
        const returnData: AlarmChartData = {
            High: 0,
            Medium: 0,
            Low: 0,
            data: {
                options: {},
                labels: [],
                datasets: [
                    {
                        label: 'Low Resolved',
                        backgroundColor: this.getSeverityColour(5, true),
                        borderColor: this.getSeverityColour(5, true),
                        fill: true,
                        data: [],

                        borderWidth: 1,
                        borderRadius: 2,
                        borderSkipped: true
                    },
                    {
                        label: 'Medium Resolved',
                        backgroundColor: this.getSeverityColour(3, true),
                        borderColor: this.getSeverityColour(5, true),
                        fill: true,
                        data: [],

                        borderWidth: 1,
                        borderRadius: 2,
                        borderSkipped: true
                    },
                    {
                        label: 'High Resolved',
                        backgroundColor: this.getSeverityColour(1, true),
                        borderColor: this.getSeverityColour(1, true),
                        fill: true,
                        data: [],

                        borderWidth: 1,
                        borderRadius: 2,
                        borderSkipped: true
                    },
                    {
                        label: 'Low Active',
                        backgroundColor: this.getSeverityColour(5, false),
                        borderColor: this.getSeverityColour(5, false),
                        fill: true,
                        data: [],

                        borderWidth: 1,
                        borderRadius: 2,
                        borderSkipped: true
                    },
                    {
                        label: 'Medium Active',
                        backgroundColor: this.getSeverityColour(3, false),
                        borderColor: this.getSeverityColour(5, false),
                        fill: true,
                        data: [],

                        borderWidth: 1,
                        borderRadius: 2,
                        borderSkipped: true
                    },
                    {
                        label: 'High Active',
                        backgroundColor: this.getSeverityColour(1, false),
                        borderColor: this.getSeverityColour(1, false),
                        fill: true,
                        data: [],

                        borderWidth: 1,
                        borderRadius: 2,
                        borderSkipped: true
                    }
                ]
            }
        };

        alarmData
            .sort((a, b) => b.TotalAlarms - a.TotalAlarms)
            .slice(0, 14)
            .forEach(alarm => {
                returnData.data.labels.push(this.truncateString(alarm.EquipmentName, 15));
                returnData.data.datasets[5].data.push(alarm.HighActive);
                returnData.data.datasets[4].data.push(alarm.MediumActive);
                returnData.data.datasets[3].data.push(alarm.LowActive);
                returnData.data.datasets[2].data.push(alarm.HighResolved);
                returnData.data.datasets[1].data.push(alarm.MediumResolved);
                returnData.data.datasets[0].data.push(alarm.LowResolved);

                returnData.High += alarm.HighActive + alarm.HighResolved;
                returnData.Medium += alarm.MediumActive + alarm.MediumResolved;
                returnData.Low += alarm.LowActive + alarm.LowResolved;
            });

        return returnData;
    }

    private processEquipmentTableData(summaryData: AlarmModel[]): EquipmentAlarm[] {
        const returnData = summaryData.reduce((accumulator: EquipmentAlarm[], current: AlarmModel) => {
            const found = accumulator.find(a => a.equipmentId === current.EquipmentId);

            if (!found) {
                accumulator.push({
                    equipmentId: current.EquipmentId,
                    equipmentName: current.EquipmentName,
                    count: current.Alarm.TotalAlarms,
                    alarms: [
                        {
                            alarmName: current.Alarm.AlarmName,
                            count: current.Alarm.TotalAlarms,
                            resolved: current.Alarm.ResolvedAlarms.length,
                            unresolved: current.Alarm.UnresolvedAlarms.length,
                            severity: current.Alarm.Severity
                        }
                    ]
                });

                return accumulator;
            }

            found.count += current.Alarm.TotalAlarms;
            found.alarms.push({
                alarmName: current.Alarm.AlarmName,
                resolved: current.Alarm.ResolvedAlarms.length,
                unresolved: current.Alarm.UnresolvedAlarms.length,
                count: current.Alarm.TotalAlarms,
                severity: current.Alarm.Severity
            });

            return accumulator;
        }, []);

        return returnData;
    }

    private processTimeChartData(alarmData: AlarmBySeverity[]): TimeChartData {
        const returnData: TimeChartData = {
            High: 0,
            Medium: 0,
            Low: 0,
            data: {
                options: {},
                labels: [],
                datasets: [
                    {
                        label: 'Low',
                        backgroundColor: this.getSeverityColour(5, false),
                        borderColor: 'white',
                        pointRadius: 0,
                        fill: true,
                        data: [],
                        borderJoinStyle: 'round',
                        borderWidth: 2
                    },
                    {
                        label: 'Medium',
                        backgroundColor: this.getSeverityColour(3, false),
                        borderColor: 'white',
                        pointRadius: 0,
                        fill: true,
                        data: [],
                        borderJoinStyle: 'round',
                        borderWidth: 2
                    },
                    {
                        label: 'High',
                        backgroundColor: this.getSeverityColour(1, false),
                        borderColor: 'white',
                        pointRadius: 0,
                        fill: true,
                        data: [],
                        borderJoinStyle: 'round',
                        borderWidth: 2
                    }
                ]
            }
        };

        alarmData
            .sort((a, b) => a.Timestamp.getTime() - b.Timestamp.getTime())
            .forEach(alarm => {
                const highActive = alarm.Severity === 1 || alarm.Severity === 2 ? alarm.TotalActive : 0;
                const mediumActive = alarm.Severity === 3 || alarm.Severity === 4 ? alarm.TotalActive : 0;
                const lowActive = alarm.Severity > 4 ? alarm.TotalActive : 0;

                const index = returnData.data.datasets[0].data
                    .map(element => element.x.getTime())
                    .indexOf(alarm.Timestamp.getTime());

                if (index === -1) {
                    returnData.data.datasets[0].data.push({ x: alarm.Timestamp, y: lowActive });
                    returnData.data.datasets[1].data.push({ x: alarm.Timestamp, y: mediumActive });
                    returnData.data.datasets[2].data.push({ x: alarm.Timestamp, y: highActive });
                } else {
                    returnData.data.datasets[0].data[index].y += lowActive;
                    returnData.data.datasets[1].data[index].y += mediumActive;
                    returnData.data.datasets[2].data[index].y += highActive;
                }

                returnData.High += highActive;
                returnData.Medium += mediumActive;
                returnData.Low += lowActive;
            });

        return returnData;
    }

    private truncateString(str: string, num: number) {
        if (str.length <= num) {
            return str;
        }

        return str.slice(0, num) + '...';
    }

    public getSeverityColour(severity: number, resolved: boolean): string {
        const red = severity === 1 || severity === 2;
        const amber = severity === 3 || severity === 4;
        const blue = severity > 4;
        if (red) {
            return !resolved ? this.dashletService.getChartColors().red : this.dashletService.getChartColors().redDark;
        } else if (amber) {
            return !resolved
                ? this.dashletService.getChartColors().amber
                : this.dashletService.getChartColors().amberDark;
        } else if (blue) {
            return !resolved
                ? this.dashletService.getChartColors().blue
                : this.dashletService.getChartColors().blueDark;
        } else {
            return '';
        }
    }
}
