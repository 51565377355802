import { Point, Regex, Sort } from '@models/index';

export class CPUUsageData {
    private _cpuUsageHistory: Point[];
    commandTypeId: string;
    thresholdsNumber: number;
    dataExpired: boolean;
    /**
     *
     */
    constructor(commandTypeId: string) {
        this._cpuUsageHistory = [];
        this.commandTypeId = commandTypeId;
        this.thresholdsNumber = 0;
        this.dataExpired = true;
    }

    public getCommandTypeId() {
        return this.commandTypeId;
    }

    /**
     *
     */
    public updateCPUUsageHistory(time: Date, cpuUsagePercentage: string) {
        if (Regex.stringIsNumber(cpuUsagePercentage)) {
            if (this.cpuUsageHistory.length > 50) {
                this.cpuUsageHistory.shift();
            }
            this.cpuUsageHistory.push({ x: time, y: +Number.parseFloat(cpuUsagePercentage).toPrecision(2) });
        }
        Sort.sortDates(this._cpuUsageHistory);
    }

    public refreshCPUUsageData() {
        this.updateCPUUsageHistory(new Date(), this.getCurrentCPUUsagePercentage());
    }

    public getCurrentCPUUsageProportion() {
        if (this._cpuUsageHistory.length > 0 && !this.dataExpired) {
            return (this._cpuUsageHistory[this._cpuUsageHistory.length - 1].y / 100).toString();
        } else {
            return '---';
        }
    }

    public getCurrentCPUUsagePercentage() {
        if (this._cpuUsageHistory.length > 0 && !this.dataExpired) {
            return this._cpuUsageHistory[this._cpuUsageHistory.length - 1].y.toString();
        } else {
            return '---';
        }
    }

    /**
     *
     */
    public getCPUUsageSeverity() {
        const cpuUsageProportion = this.getCurrentCPUUsageProportion();
        if (Regex.stringIsNumber(cpuUsageProportion)) {
            if (+cpuUsageProportion < 0.8) {
                return 0;
            } else if (+cpuUsageProportion < 0.9) {
                return 1;
            } else {
                return 2;
            }
        }
        return;
    }

    /**
     * Getter cpuUsageHistory
     * @return {number[]}
     */
    public get cpuUsageHistory(): Point[] {
        return this._cpuUsageHistory;
    }
}
